import React from "react";
export const Menu = () => {
    return <div>
        <img style={
            {
                width: '100%',
                height: 'auto'
            }
        } src="/menu.png"/>
    </div>
}